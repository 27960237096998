import * as React from 'react';
import { http } from '../structures/http';
import { IRifa } from '../structures/IRifa';
import { IBoleta, IRtaBoleta } from '../structures/IBoleta';
import { IRtaRifa } from '../structures/IRifa';
import { Navigate } from 'react-router-dom';
//import { RouteComponentProps } from 'react-router-dom';
import { Dropdown, Table } from 'react-bootstrap';
import { AppContext } from '../AppContext';
//import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
//const css = require('react-datepicker/dist/react-datepicker.css');
const logo = require('../img/logo.svg').default;

class Rifa extends React.Component<IConsumptionProps, IConsumptionState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    
    constructor(props: IConsumptionProps) {

        super(props);

        this.state = {
            rifa: { id: '', nombre: '' },
            rifas: [],
            boletas: [],
            show: false
        }
    }

    componentDidMount() {

        this.loadRifas();
    }

    

    async loadRifas() {

        const url: string = this.context.url + "/listarifas";
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');

        const data = await http<IRtaRifa>(
            new Request(url, {
                method: "post",
                headers: requestHeaders
            })
        );

        if (data) {

            if (data.codigo == 100) {
                this.setState({
                    rifas: data.rta,
                    show: false,
                    rifa: data.rta[0]
                },() => this.showTable());
            }
            else {

                this.setState({
                    show: false
                });

                alert(data.rta);
            }

        }
        else {
            alert(data);
        }
    }

    handleDate(e:Date | null) {
       

    }

    /*updateTable(e: any) {

        this.setState({
            rifa: this.state.rifas[e],
        }, () => this.showTable());
    }*/

    showTable() {

        this.loadBoletas();
    }

    async loadBoletas() {

        const url: string = this.context.url + "/obtenerboletas";
        const params = "cid=" + this.context.sid + "&rid=" + this.state.rifa.id;
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');


        const data = await http<IRtaBoleta>(
            new Request(url, {
                method: "post",
                headers: requestHeaders,
                body: params
            })
        );

        if (data) {
            
            if (data.codigo == 100) {

                    this.setState({
                    boletas: data.rta == null ? [] : data.rta,
                    show: false
                });
            }
            else {

                this.setState({
                    boletas: [],
                    show: false
                });

                alert(data.rta);
            }

        }
        else {
            alert(data);
        }
    }

    render() {

        if (this.context.sid == '') {
            return <Navigate
                to={{
                    pathname: '/',

                }} />
        }

        return (
            <div id="page-wrap">
                
                <div className="pagina">
                    <img className="logo-sm mb-4" src={logo} />
                    
                    <br />
                    <Table striped bordered hover variant="dark" responsive className="tabla">
                        <thead>
                            <tr>
                                <th>FECHA</th>
                                <th>CODIGO</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.boletas.map((item, key) => {
                                return <tr key={key}>
                                    <td >{item.fecha.slice(0, 10)}</td>
                                    <td>{item.numero}</td>
                                    
                                </tr>
                            })}
                        </tbody>
                    </Table>
                    
                    <h1 className="h5 mb-3 font-weight-normal float-right" ><strong>TOTAL</strong> {this.state.boletas.length} códigos</h1>
                    
                </div>
            </div>
        );
    }
}

//Rifa.contextType = AppContext;

interface IConsumptionProps {

}

interface IConsumptionState {
    rifa: IRifa;
    rifas: IRifa[];
    boletas: IBoleta[];
    show: boolean;
}

export default Rifa;