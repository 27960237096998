import * as React from 'react';
import { Navigate, Link } from 'react-router-dom';
import { http, ILogin } from '../structures/http';
import { IRta } from '../structures/IRta';
import { Spinner, Modal } from 'react-bootstrap';
import * as crypto from 'crypto';
import { AppContext } from '../AppContext';
import ReactDOM from 'react-dom';
import { AppContextProvider } from '../AppContext';

const logo = require('../img/logo.svg').default;

class Login extends React.Component<ILoginProps, ILoginState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: ILoginProps) {

        super(props);

        this.state = {
            uname: '',
            show: false,
            checked: false
        }

    }

    handleCheck(e: React.FormEvent<HTMLInputElement>) {
        this.setState({
            checked: !this.state.checked
        });


    }

    handleLogin(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();

        if (this.state.checked)
            this.login();
        else
            alert('Debes aceptar los terminos y condiciones y la politica de proteccion de datos de Combuscol');
    }

    async login() {

        const params = "ced=" + this.state.uname;
        const url: string = this.context.url + "/autenticarcedula?";
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');

        const data = await http<IRta>(
            new Request(url, {
                method: "post",
                headers: requestHeaders,
                body: params
            })
        );

        if (data) {
            if (data.codigo == 100) {

                this.context.setSession(data.rta.id);

                this.setState({
                    uname: this.state.uname,
                    show: false
                });
            }
            else {

                this.setState({
                    show: false
                });

                alert(data.rta);
            }

        }
        else {
            alert(data);
        }
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {

        const { name, value } = e.target;

        this.setState({
            [name]: value
        } as any);
    }

    render() {

        if (this.context.sid != '') {

            return <Navigate
                to={{
                    pathname: '/rifas',
                }} />
        }

        return (

            <div id="page-wrap">
                
                <form onSubmit={e => this.handleLogin(e)} className="form-signin">
                    <img className="logo" src={logo} />
                    <br /><br />
                    <h1 className="h5 mb-3 font-weight-normal">Por favor ingrese</h1>
                    <div className="form-group">
                        <input
                            type="text"
                            name="uname"
                            onChange={e => this.handleInputChange(e)}
                            className="form-control my-control"
                            placeholder="Número de Identificación"
                            value={this.state.uname}
                        />
                    </div>

                    <div className="form-group form-check ">
                        <input
                            name="check"
                            type="checkbox"
                            checked={this.state.checked}
                            onChange={e => this.handleCheck(e)}
                            className="form-check-input"
                            id="check" />
                        <label className="form-check-label text-left ml-3" >He leído y acepto los <a href="https://www.combuscol.com/docs/terminos-y-condiciones-premiaton-combuscol0822.pdf" target="_blank">términos y condiciones de la actividad promocional</a> y <a href="https://www.combuscol.com/docs/Manual-de-politicas-y-procedimientos-Proteccion-de-datos-Combuscol-1.pdf" target="_blank">política de protección de datos</a> Combuscol.</label>
                    </div>
                    <button type="submit" className="btn btn-danger btn-block my-control">
                        INGRESAR
                    </button><br /><br />

                </form>


                <Modal show={this.state.show} dialogClassName="my-modal" size="sm" aria-labelledby="example-modal-sizes-title-sm" centered>

                    <Modal.Body >
                        Un momento por favor ....
                            <br />
                        <br />
                        <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                            variant="danger"
                        />
                    </Modal.Body>


                </Modal>
            </div> 
            
        )

    }
}

//Login.contextType = AppContext;

interface ILoginProps {

}

interface ILoginState {
    uname: string;
    show: boolean;
    checked: boolean;
}

export default Login;