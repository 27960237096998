import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './app/App';
import { TopMenu } from './app/components/TopMenu';
//import {AppContextProvider} from './app/AppContext';
import './styles/main.scss';

const app = document.getElementById('app');

ReactDOM.render(
    <div id="outer-container">
        
        <App title='Tickets Combuscol' ></App> 
        
    </div>,
    app
);