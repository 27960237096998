import * as React from 'react';
import Login from '../pages/Login';
import { HashRouter, Route, Link } from 'react-router-dom';
import { push as Menu } from 'react-burger-menu';
import { AppContext } from '../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome , faGasPump, faGift, faDice, faSignOutAlt, faSignInAlt} from "@fortawesome/free-solid-svg-icons";



export class TopMenu extends React.Component<ITopMenuProps, ITopMenuState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    
    constructor(props: ITopMenuProps) {

        super(props);

        this.state = {
            redirect: '',
        }
    }

    cerrarSesion(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
    }

    render() { 
        
        return (
            
            <Menu pageWrapId={this.props.pageWrapId} outerContainerId={this.props.outerContainerId}>

                {this.context.sid == '' ? '' : <Link id="logout" className="menu-item" to="/logout" ><FontAwesomeIcon icon={faSignOutAlt} size="1x" className='menu-icon'/>&nbsp;&nbsp;Cerrar Sesión</Link>}
                
            </Menu>
        );
    }
}

//TopMenu.contextType = AppContext;

interface ITopMenuProps {
    pageWrapId: string;
    outerContainerId: string;
}

interface ITopMenuState {
    redirect: string;
}