import * as React from 'react'

export const AppContext = React.createContext({
    sid: '',
    url: '',
    setSession: (sid: string) => { }
});

export class AppContextProvider extends React.Component<IAppContextProviderProps, IAppContextProviderState> {

    constructor(props: IAppContextProviderProps) {
        
        super(props);
        
        this.state = {
            sid: '',
            url: '',
            
        };
        
        this.setSession = this.setSession.bind(this);
    }

    setSession(sessid: string) {
        
        this.setState({
            sid: sessid
        });
    }



    render() {
        return (
            
            <AppContext.Provider value={{
                sid: this.state.sid,
                setSession: this.setSession,
                url:'https://api.combuscol.co'

            }}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}

interface IAppContextProviderProps {
    children: React.ReactNode;
}

interface IAppContextProviderState {
    sid: string;
    url: string;

}

export const AppContextConsumer = AppContext.Consumer;